<template>
  <CForm @submit="send">
    <h3 class="text-center" :class="{ 'mb-4': sent }">{{ $t('forgot_password') }}</h3>
    <p class="text-muted text-center" v-if="!sent">{{ $t('enter_your_email_in_the_following_input') }}</p>
    <CAlert color="danger" v-if="!_.isEmpty(error)" closeButton>
      {{ error }}
    </CAlert>
    <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)">
      {{ sentMessage }}
    </CAlert>
    <div v-if="!_.isEmpty(sentMessage) && sent" class="text-center">
      <router-link :to="{ name: 'Login', params: { languageCode: $store.state.languageCode } }">{{ $t('go_to_login_page') }}</router-link>
    </div>
    <div v-if="!sent">
      <CInput
          :placeholder="$t('email')"
          autocomplete="email"
          v-model="email"
          @keydown="resetErrors"
          :invalid-feedback="_.has(errors, 'email') ? errors.email : ''"
          :is-valid="_.has(errors, 'email') ? false : null"
      >
        <template #prepend-content><CIcon name="cil-user"/></template>
      </CInput>
      <CRow class="flex-row align-items-center">
        <CCol col="6" class="text-left">
          <CButton type="submit" color="red" class="px-4">{{ $t('send') }}</CButton>
        </CCol>
        <CCol col="6" class="text-right">
          <router-link :to="{ name: 'Login', params: { languageCode: $store.state.languageCode } }">{{ $t('login') }}</router-link>
        </CCol>
      </CRow>
    </div>
  </CForm>
</template>

<script>
import {forgetPassword} from "../../api/auth";

export default {
  name: 'Login',
  data() {
    return {
      email: 'admin@webaty.com',
      errors: {},
      error: '',
      sentMessage: '',
      sent: false,
    };
  },
  watch: {
    '$store.state.languageCode': function () {
      this.error = ''
    }
  },
  methods: {
    resetErrors() {
      this.errors = {}
      this.error = ''
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors()

      if (this.email === '') {
        this.errors.email = this.$t('field_is_required', { field: this.$t('email') });
        hasErrors = true
      }

      this.$forceUpdate()
      if (!hasErrors) {
        callback()
      }
    },
    isValidEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    send(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault()
      }
      this.validate(() => {
        forgetPassword(this, this.email, (data) => {
          if (data.sent) {
            this.sentMessage = data.message;
          } else {
            this.error = data.message;
          }
          this.sent = data.sent;
        }, (error) => {
          this.error = error;
        });
      })
    },
  },
}
</script>
