import axios from "./_base";
import errorHandler from "./_errorHandler";

export const login = (vue, email, password, callback, errorCallback) => {
    axios.post('http://192.168.43.96/api/login', {
        email,
        password,
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const forgetPassword = (vue, email, callback, errorCallback) => {
    axios.post('http://192.168.43.96/api/reset_password_request', {
        email,
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        if (error.response.status === vue.$config.defaultErrorStatusCode && vue._.has(error, 'response.data.errors.email') && vue._.isArray(error.response.data.errors.email) && !vue._.isEmpty(error.response.data.errors.email)) {
            errorCallback(error.response.data.errors.email[0])
        } else {
            errorHandler(vue, error, errorCallback)
        }
    });
};
